<template>
  <va-card :title="'Using: ' + EvaluationName">
    <div class="row align--center">
      <div class="flex xs12 md6">
     
        <va-input
          :value="term"
          :placeholder="$t('tables.searchByName')"
          @input="search"
          removable
        >
        <va-icon
            name="fa fa-search"
            slot="prepend"
          />
        </va-input>
      </div>

      <div class="flex xs12 md3 offset--md3">
        <va-select
          v-model="perPage"
          :label="$t('tables.perPage')"
          :options="perPageOptions"
          no-clear
        />
      </div>
    </div>

    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)"
      clickable
    >
    </va-data-table>
  </va-card>
</template>

<script>
import {debounce} from 'lodash';
import firebase from 'firebase';
export default {
  data() {
    return {
      term: null,
      perPage: '10',
      EvaluationName: '',
      perPageOptions: ['4', '10', '15', '20'],
    };
  },
  async mounted() {
    //get from evalTemplates, the title of the one that has default = true
    await firebase.firestore().collection('evalTemplates').where('default', '==', true).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.EvaluationName = doc.data().title;
      });
    });
  },
  props: {
      model: {
      type: Array,
      default: () => {},
      },
  },
  computed: {
    fields() {
      return [{
        name: '__slot:trend',
        width: '30px',
        height: '45px',
        dataClass: 'text-center',
      }, 
      {
        name: 'userID',
        title: this.$t('User ID'),
        width: '8%',
      }, 
      {
        name: 'Label1',
        title: this.$t('Q:1'),
        width: '8%',
      }, 
      {
        name: 'Label2',
        title: this.$t('Q:2'),
        width: '8%',
      }, 
      {
        name: 'Label3',
        title: this.$t('Q:3'),
        width: '8%',
      }, 
      {
        name: 'Label4',
        title: this.$t('Q:4'),
        width: '8%',
      }, 
      {
        name: 'Label5',
        title: this.$t('Q:5'),
        width: '8%',
      }, 
      {
        name: 'Label6',
        title: this.$t('Q:6'),
        width: '8%',
      }, 
      {
        name: 'Label7',
        title: this.$t('Q:7'),
        width: '8%',
      }, 
      {
        name: 'Label8',
        title: this.$t('Q:8'),
        width: '8%',
      }, 
      {
        name: 'Label9',
        title: this.$t('Q:9'),
        width: '8%',
      }, 
      {
        name: 'Label10',
        title: this.$t('Q:10'),
        width: '8%',
      }, 
    ];
    },
    filteredData() {
      return this.model.filter((item) => {
        if (!this.term || this.term.length < 1) {
          //foreach model we need to add the data to the table
          this.model.forEach((item, key) => {
              item.Label1 = item.answers[0] != undefined ? item.answers[0].answerLabel : '-';
              item.Label2 = item.answers[1] != undefined ? item.answers[1].answerLabel : '-';
              item.Label3 = item.answers[2] != undefined ? item.answers[2].answerLabel : '-';
              item.Label4 = item.answers[3] != undefined ? item.answers[3].answerLabel : '-';
              item.Label5 = item.answers[4] != undefined ? item.answers[4].answerLabel : '-';
              item.Label6 = item.answers[5] != undefined ? item.answers[5].answerLabel : '-';
              item.Label7 = item.answers[6] != undefined ? item.answers[6].answerLabel : '-';
              item.Label8 = item.answers[7] != undefined ? item.answers[7].answerLabel : '-';
              item.Label9 = item.answers[8] != undefined ? item.answers[8].answerLabel : '-';
              item.Label10 = item.answers[10] != undefined ? item.answers[10].answerLabel : '-';
          });
         //order by creationDate
         this.model.sort((a, b) => {
            return new Date(b.creationDate) - new Date(a.creationDate);
         });
         return this.model
       }
        let filter1 = item.Label1?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter2 = item.Label2?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter3 = item.Label3?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter4 = item.Label4?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter5 = item.Label5?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter6 = item.Label6?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter7 = item.Label7?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter8 = item.Label8?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter9 = item.Label9?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter10 = item.Label10?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        let filter11 = item.Label11?.toLowerCase().indexOf(this.term.toLowerCase()) > -1;
        return filter1 || filter2 || filter3 || filter4 || filter5 || filter6 || filter7 || filter8 || filter9 || filter10 || filter11;
      });
    },
  },
  methods: {
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 400),
  },
};
</script>
